
require("knockout-mapping/dist/knockout.mapping")
import ko from "./vendor/ko.js"
import mapping from "knockout-mapping/dist/knockout.mapping.js"
import "./vendor/orbit-controls.js"
import EB_AFIT, { Item } from "./vendor/EB_AFIT.js"
import AlgorithmPackingResult from "./vendor/AlgorithmPackingResult.js"
import ContainerPackingResult from "./vendor/ContainerPackingResult.js"
import { json } from "d3"
// import * as THREE from 'three';

if ($(".package-container").length) {
	var scene;
	var camera;
	var renderer;
	var controls;
	var viewModel;
	var itemMaterial;
	var seconds = 0;
	var tens = 0;
	var mainContainer = $("#drawing-container");
	var colorObjs = {};
	var isAutomatic;

	async function PackContainers(request) {

		let items = request["ItemsToPack"];
		let results = [];
		// items.push(new Item(0,0,0,0,0));
		let tempUnpackedItems = items;
		// do {
		request["Containers"].forEach(container => {
			let Interval = setInterval(startTimer, 10);
			let containerPackingResult = new ContainerPackingResult(container.ID);
			let algorithmResult = new AlgorithmPackingResult(new EB_AFIT(container, tempUnpackedItems));
			clearInterval(Interval);

			let containerVolume = container.Length * container.Width * container.Height;
			let itemVolumePacked = sum(algorithmResult.PackedItems, 'Volume');
			let itemVolumeUnpacked = sum(algorithmResult.UnpackedItems, 'Volume');
			tempUnpackedItems = algorithmResult.UnpackedItems;

			algorithmResult.PercentContainerVolumePacked = Math.round(itemVolumePacked / containerVolume * 100, 2);
			algorithmResult.PercentItemVolumePacked = Math.round(itemVolumePacked / (itemVolumePacked + itemVolumeUnpacked) * 100, 2);

			containerPackingResult.AlgorithmPackingResults.push(algorithmResult);
			results.push(containerPackingResult);
		});
		// }while(tempUnpackedItems.length > 0);

		return results;
	};

	function InitializeDrawing() {
		var container = $('#drawing-container');
		scene = new THREE.Scene();
		camera = new THREE.PerspectiveCamera(50, container.width() / container.height(), 1, 1000);
		camera.lookAt(scene.position);
		// camera.position.z = 400;

		//var axisHelper = new THREE.AxisHelper( 5 );
		//scene.add( axisHelper );

		// const geometry = new THREE.BoxGeometry( 10, 10, 10 );
		// const material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
		// const cube = new THREE.Mesh( geometry, material );
		// scene.add( cube );

		// Get the item stuff ready.
		itemMaterial = new THREE.MeshNormalMaterial({ transparent: true, opacity: 0.2 });

		renderer = new THREE.WebGLRenderer({ antialias: true }); // WebGLRenderer CanvasRenderer
		renderer.setClearColor(0xf0f0f0);
		renderer.setPixelRatio(mainContainer.devicePixelRatio);
		renderer.setSize(mainContainer.width(), mainContainer.height());
		container.append(renderer.domElement);

		controls = new THREE.OrbitControls(camera, renderer.domElement);
		window.addEventListener('resize', onWindowResize, false);

		animate();
	};

	function onWindowResize() {
		// camera.aspect = mainContainer.width() / mainContainer.height();
		// camera.updateProjectionMatrix();
		// renderer.setSize( mainContainer.width() / 2, mainContainer.height() / 2 );
	}
	//
	function animate() {
		requestAnimationFrame(animate);
		controls.update();
		render();
	}
	function render() {
		renderer.render(scene, camera);
	}

	var ViewModel = function () {
		var self = this;

		self.ItemCounter = 0;
		self.ContainerCounter = 0;

		self.ItemsToRender = ko.observableArray([]);
		self.LastItemRenderedIndex = ko.observable(-1);

		self.ContainerOriginOffset = {
			x: 0,
			y: 0,
			z: 0
		};

		self.AlgorithmsToUse = ko.observableArray([]);
		self.ItemsToPack = ko.observableArray([]);
		self.Containers = ko.observableArray([]);

		self.NewItemToPack = ko.mapping.fromJS(new ItemToPack());
		self.NewContainer = ko.mapping.fromJS(new Container());

		self.GenerateItemsToPack = function () {
			self.ItemsToPack([]);
			self.ItemsToPack.push(ko.mapping.fromJS({ ID: 1000, Name: 'Item 1', Length: 5, Width: 4, Height: 2, Quantity: 1 }));
			self.ItemsToPack.push(ko.mapping.fromJS({ ID: 1001, Name: 'Item 2', Length: 2, Width: 1, Height: 1, Quantity: 3 }));
			self.ItemsToPack.push(ko.mapping.fromJS({ ID: 1002, Name: 'Item 3', Length: 9, Width: 7, Height: 3, Quantity: 4 }));
			self.ItemsToPack.push(ko.mapping.fromJS({ ID: 1003, Name: 'Item 4', Length: 13, Width: 6, Height: 3, Quantity: 8 }));
			self.ItemsToPack.push(ko.mapping.fromJS({ ID: 1004, Name: 'Item 5', Length: 17, Width: 8, Height: 6, Quantity: 1 }));
			self.ItemsToPack.push(ko.mapping.fromJS({ ID: 1005, Name: 'Item 6', Length: 3, Width: 3, Height: 2, Quantity: 2 }));
		};

		self.GenerateContainers = function () {
			self.Containers([]);
			self.Containers.push(ko.mapping.fromJS({ ID: 1000, Name: 'Box1', Length: 15, Width: 13, Height: 9, AlgorithmPackingResults: [] }));
			self.Containers.push(ko.mapping.fromJS({ ID: 1001, Name: 'Box2', Length: 23, Width: 9, Height: 4, AlgorithmPackingResults: [] }));
			self.Containers.push(ko.mapping.fromJS({ ID: 1002, Name: 'Box3', Length: 16, Width: 16, Height: 6, AlgorithmPackingResults: [] }));
			self.Containers.push(ko.mapping.fromJS({ ID: 1003, Name: 'Box4', Length: 10, Width: 8, Height: 5, AlgorithmPackingResults: [] }));
			self.Containers.push(ko.mapping.fromJS({ ID: 1004, Name: 'Box5', Length: 40, Width: 28, Height: 20, AlgorithmPackingResults: [] }));
			self.Containers.push(ko.mapping.fromJS({ ID: 1005, Name: 'Box6', Length: 29, Width: 19, Height: 4, AlgorithmPackingResults: [] }));
			self.Containers.push(ko.mapping.fromJS({ ID: 1006, Name: 'Box7', Length: 18, Width: 13, Height: 1, AlgorithmPackingResults: [] }));
			self.Containers.push(ko.mapping.fromJS({ ID: 1007, Name: 'Box8', Length: 6, Width: 6, Height: 6, AlgorithmPackingResults: [] }));
			self.Containers.push(ko.mapping.fromJS({ ID: 1008, Name: 'Box9', Length: 8, Width: 5, Height: 5, AlgorithmPackingResults: [] }));
			self.Containers.push(ko.mapping.fromJS({ ID: 1009, Name: 'Box10', Length: 18, Width: 13, Height: 8, AlgorithmPackingResults: [] }));
			self.Containers.push(ko.mapping.fromJS({ ID: 1010, Name: 'Box11', Length: 17, Width: 16, Height: 15, AlgorithmPackingResults: [] }));
			self.Containers.push(ko.mapping.fromJS({ ID: 1011, Name: 'Box12', Length: 32, Width: 10, Height: 9, AlgorithmPackingResults: [] }));
			self.Containers.push(ko.mapping.fromJS({ ID: 1012, Name: 'Box13', Length: 60, Width: 60, Height: 60, AlgorithmPackingResults: [] }));
		};

		self.AddAlgorithmToUse = function () {
			var algorithmID = $('#algorithm-select option:selected').val();
			var algorithmName = $('#algorithm-select option:selected').text();
			self.AlgorithmsToUse.push({ AlgorithmID: algorithmID, AlgorithmName: algorithmName });
		};

		self.RemoveAlgorithmToUse = function (item) {
			self.AlgorithmsToUse.remove(item);
		};

		self.AddNewItemToPack = function () {
			self.NewItemToPack.ID(self.ItemCounter++);
			self.ItemsToPack.push(ko.mapping.fromJS(ko.mapping.toJS(self.NewItemToPack)));
			self.NewItemToPack.Name('');
			self.NewItemToPack.Length('');
			self.NewItemToPack.Width('');
			self.NewItemToPack.Height('');
			self.NewItemToPack.Quantity('');
		};

		self.ItemChanged = function (item) {
			for (var i = 0; i < self.ItemsToPack().length; i++) {
				if (self.ItemsToPack()[i].ID() == item.ID()) {
					self.ItemsToPack()[i].Length(item.Length());
				}
			}
		}

		self.RemoveItemToPack = function (item) {
			self.ItemsToPack.remove(item);
		};

		self.AddNewContainer = function () {
			self.NewContainer.ID(self.ContainerCounter++);
			self.Containers.push(ko.mapping.fromJS(ko.mapping.toJS(self.NewContainer)));
			self.NewContainer.Name('');
			self.NewContainer.Length('');
			self.NewContainer.Width('');
			self.NewContainer.Height('');
		};

		self.RemoveContainer = function (item) {
			self.Containers.remove(item);
		};

		self.PackContainers = function () {
			var algorithmsToUse = [];

			self.AlgorithmsToUse().forEach(algorithm => {
				algorithmsToUse.push(algorithm.AlgorithmID);
			});

			var itemsToPack = [];

			self.ItemsToPack().forEach(item => {
				var itemToPack = {
					ID: item.ID(),
					Name: item.Name(),
					Dim1: item.Length(),
					Dim2: item.Width(),
					Dim3: item.Height(),
					Quantity: item.Quantity()
				};

				itemsToPack.push(itemToPack);
			});

			var containers = [];

			// Send a packing request for each container in the list.
			self.Containers().forEach(container => {
				var containerToUse = {
					ID: container.ID(),
					Length: container.Length(),
					Width: container.Width(),
					Height: container.Height()
				};

				containers.push(containerToUse);
			});

			// Build container packing request.
			var request = {
				Containers: containers,
				ItemsToPack: itemsToPack,
				AlgorithmTypeIDs: algorithmsToUse
			};

			PackContainers(request)
				.then(response => {
					// let tempContainers = self.Containers();
					// self.Containers([]);
					response.forEach(containerPackingResult => {
						// self.Containers.push(ko.mapping.fromJS({ ID: 1000, Name: 'Test1', Length: 15, Width: 13, Height: 9, AlgorithmPackingResults: containerPackingResult["AlgorithmPackingResults"] }));
						let aResults = [];
						let packageItems = [];
						containerPackingResult["AlgorithmPackingResults"].forEach(packingResult => {
							aResults.push(ko.mapping.fromJS({
								algorithmName: "Algo Name", PackTimeInMilliseconds: packingResult.PackTimeInMilliseconds, PercentContainerVolumePacked: packingResult.PercentContainerVolumePacked,
								PackedItems: packingResult.PackedItems, UnpackedItems: packingResult.UnpackedItems
							}));
							packageItems.push(packingResult.PackedItems);
						});
						let ctr = 1;
						self.Containers().forEach(container => {
							if (container.ID() == containerPackingResult["ContainerID"]) {
								container.Name("Box " + ctr);
								ctr++;
								container.AlgorithmPackingResults(aResults);

								if (container.ID() == 1000) {
									ShowSpecificPackingView(container, packageItems);
								}
							}
						});
					});
				});
		};

		self.ShowPackingView = function (algorithmPackingResult) {
			var container = this;
			var selectedObject = scene.getObjectByName('container');
			scene.remove(selectedObject);

			for (var i = 0; i < 1000; i++) {
				var selectedObject = scene.getObjectByName('cube' + i);
				scene.remove(selectedObject);
			}

			camera.position.set(container.Length, container.Length, container.Length);

			self.ItemsToRender(algorithmPackingResult.PackedItems[0]);
			self.LastItemRenderedIndex(-1);

			self.ContainerOriginOffset.x = -1 * container.Length / 2;
			self.ContainerOriginOffset.y = -1 * container.Height / 2;
			self.ContainerOriginOffset.z = -1 * container.Width / 2;

			var geometry = new THREE.BoxGeometry(container.Length, container.Height, container.Width);
			var geo = new THREE.EdgesGeometry(geometry); // or WireframeGeometry( geometry )
			var mat = new THREE.LineBasicMaterial({ color: 0x000000, linewidth: 2 });
			var wireframe = new THREE.LineSegments(geo, mat);
			wireframe.position.set(0, 0, 0);
			wireframe.name = 'container';
			scene.add(wireframe);
		};

		self.AreItemsPacked = function () {
			if (self.LastItemRenderedIndex() > -1) {
				return true;
			}

			return false;
		};

		self.AreAllItemsPacked = function () {
			if (self.ItemsToRender().length === self.LastItemRenderedIndex() + 1) {
				return true;
			}

			return false;
		};

		self.PackItemInRender = function (containerInd = null) {
			let itemsContainer = $(".items-container.packed");
			var itemIndex = containerInd ? containerInd - 1 : self.LastItemRenderedIndex() + 1;

			let ind = 0;
			self.ItemsToRender()[0][itemIndex].forEach(item => {
				let value = 'cube[' + itemIndex + '][' + ind + ']';
				let colorValue = Math.random() * 0xffffff;
				colorObjs[value] = colorValue;
				itemsContainer.append("<div class='item' color='" + colorValue + "' value='" + value + "' index='" + containerInd + "'>" + item.Name + "<br/>" +
					"<span><b>(Box " + containerInd + ")</b> " + item.PackDimX + " x " + item.PackDimY + " x " + item.PackDimZ + " " + item.UnitMeasure +
					" (" + item.Weight + " " + item.WeightType + ")</span>" +
					"</div>");

				var itemOriginOffset = {
					x: item.PackDimX / 2,
					y: item.PackDimY / 2,
					z: item.PackDimZ / 2
				};

				var itemGeometry = new THREE.BoxGeometry(item.PackDimX, item.PackDimY, item.PackDimZ);

				var cubeMaterial = new THREE.MeshBasicMaterial({
					transparent: true,
					opacity: 0.2,
					color: colorValue
				});

				var cube = new THREE.Mesh(itemGeometry, cubeMaterial);
				cube.position.set(self.ContainerOriginOffset.x + itemOriginOffset.x + item.CoordX, self.ContainerOriginOffset.y + itemOriginOffset.y + item.CoordY, self.ContainerOriginOffset.z + itemOriginOffset.z + item.CoordZ);
				cube.name = value;

				var selectedContainer = scene.getObjectByName('container' + containerInd);
				selectedContainer.add(cube);
				if (containerInd > 1) {
					selectedContainer.visible = false;
				}
				ind++;
			});

			var tempContainer = scene.getObjectByName('container' + containerInd);
			if (containerInd > 1) {
				tempContainer.visible = false;
			}

			self.LastItemRenderedIndex(itemIndex);
		};

		self.UnpackItemInRender = function () {
			var selectedObject = scene.getObjectByName('cube' + self.LastItemRenderedIndex());
			scene.remove(selectedObject);
			self.LastItemRenderedIndex(self.LastItemRenderedIndex() - 1);
		};

		var ShowSpecificPackingView = function (container, packageItems) {
			var selectedObject = scene.getObjectByName('container');
			scene.remove(selectedObject);

			for (var i = 0; i < 1000; i++) {
				var selectedObject = scene.getObjectByName('cube' + i);
				scene.remove(selectedObject);
			}

			camera.position.set(container.Length(), container.Length(), container.Length());

			self.ItemsToRender(packageItems);
			self.LastItemRenderedIndex(-1);

			self.ContainerOriginOffset.x = -1 * container.Length() / 2;
			self.ContainerOriginOffset.y = -1 * container.Height() / 2;
			self.ContainerOriginOffset.z = -1 * container.Width() / 2;

			var geometry = new THREE.BoxGeometry(container.Length(), container.Height(), container.Width());
			var geo = new THREE.EdgesGeometry(geometry); // or WireframeGeometry( geometry )
			var mat = new THREE.LineBasicMaterial({ color: 0x000000, linewidth: 2 });
			var wireframe = new THREE.LineSegments(geo, mat);
			wireframe.position.set(0, 0, 0);
			wireframe.name = 'container';
			scene.add(wireframe);
		}

		var ShowSpecificPackingView1 = function (container, packageItems, containerInd) {
			var selectedObject = scene.getObjectByName('container');
			scene.remove(selectedObject);

			for (var i = 0; i < 1000; i++) {
				var selectedObject = scene.getObjectByName('cube' + i);
				scene.remove(selectedObject);
			}

			camera.position.set(container.Length, container.Length, container.Length);

			self.ItemsToRender().push(packageItems);
			self.LastItemRenderedIndex(-1);

			self.ContainerOriginOffset.x = -1 * container.Length / 2;
			self.ContainerOriginOffset.y = -1 * container.Height / 2;
			self.ContainerOriginOffset.z = -1 * container.Width / 2;

			var geometry = new THREE.BoxGeometry(container.Length, container.Height, container.Width);
			var geo = new THREE.EdgesGeometry(geometry); // or WireframeGeometry( geometry )
			var mat = new THREE.LineBasicMaterial({ color: 0x000000, linewidth: 2 });
			var wireframe = new THREE.LineSegments(geo, mat);
			wireframe.position.set(0, 0, 0);
			wireframe.name = 'container' + containerInd;
			scene.add(wireframe);

			self.PackItemInRender(containerInd);
		}

		var fetchCurrentCartPackage = function () {
			$.ajax({
				type: "GET",
				url: "/get_current_cart",
				success: function (result) {
					if (result.status == 200) {
						setPackingPackage(result.containers, result.items);
					}
					else {
						console.log("error: " + result.error)
					}
				}
			});
		}

		var fetchCurrentOrderPackage = function () {
			$.ajax({
				type: "GET",
				url: "/package/" + $(".package-container").attr("unique_identifier") + ".json?is_json=true",
				success: function (result) {
					if (result.status == 200) {
						setPackingPackage(result.containers, result.items);
						console.log("result ", result)
					}
					else {
						console.log("error: " + result.error)
					}
				}
			});
		}

		var setPackingPackage = function (containersObj, items) {
			let containers = [];
			let packageItems = [];

			var boxTabs = $(".box-tabs");
			boxTabs.html("");
			let boxInd = 1;
			let primeContainer = containersObj[0];
			$.each(containersObj, function (ind, container) {
				let boxClass = boxInd == 1 ? "box-tab active" : "box-tab";
				boxTabs.append("<div class='" + boxClass + "' value='" + boxInd + "'>Box " + boxInd + "</div>");
				containers.push({
					ID: container.id,
					Name: container.Name,
					Length: container.Length,
					Height: container.Height,
					Width: container.Width,
					UnitMeasure: container.UnitMeasure,
					CargoType: container.CargoType,
					BoxType: container.BoxType,
					Weight: weight_conversion(container.Weight, container.WeightType, primeContainer.WeightType),
					WeightType: primeContainer.WeightType
				});
				boxInd++;
			});
			// containers.push({ID: container.id, Name: container.Name, Length: container.Length, Height: container.Height, Width: container.Width});

			items.forEach(item => {
				packageItems.push({
					ID: item.ID,
					Name: item.Name,
					Dim1: item.Dim1,
					Dim2: item.Dim2,
					Dim3: item.Dim3,
					Quantity: item.Quantity,
					UnitMeasure: item.UnitMeasure,
					Weight: weight_conversion(item.Weight, item.WeightType, containers[0].WeightType),
					WeightType: containers[0].WeightType
				});
			});

			// console.log("Items " + JSON.stringify(items))

			let results = [];

			let tempUnpackedItems = packageItems;
			let hasChanged = true;

			let contInd = 1;
			containers.forEach(container => {
				let containerPackingResult = new ContainerPackingResult(container.ID);
				let algorithmResult = new AlgorithmPackingResult(new EB_AFIT(container, tempUnpackedItems));

				let containerVolume = container.Length * container.Width * container.Height;
				let itemVolumePacked = sum(algorithmResult.PackedItems, 'Volume');
				let itemVolumeUnpacked = sum(algorithmResult.UnpackedItems, 'Volume');
				tempUnpackedItems = algorithmResult.UnpackedItems;

				algorithmResult.PercentContainerVolumePacked = itemVolumePacked / containerVolume * 100, 2;
				algorithmResult.PercentItemVolumePacked = itemVolumePacked / (itemVolumePacked + itemVolumeUnpacked) * 100, 2;

				containerPackingResult.AlgorithmPackingResults.push(algorithmResult);
				results.push(containerPackingResult);
				$(".box-dimensions.box-" + contInd + " .num-items span.value").html(algorithmResult.PackedItems.length);
				$(".box-dimensions.box-" + contInd + " .total-weight span.value").html(sum(algorithmResult.PackedItems, 'Weight').toFixed(2) + " " + container.WeightType);
				contInd++;
			});

			let $cartContainer = $(".change-cart-container");

			// if(tempUnpackedItems.length > 0) {
			// 	let UnpackedContainer = $(".items-container.unpacked");
			// 	tempUnpackedItems.forEach(item => {
			// 		UnpackedContainer.append("<div class='item'>"+item.Name+"<br/>"+
			// 			"<span>"+item.Dim1+" x "+item.Dim3+" x "+item.Dim2+"</span>"+
			// 		"</div>");
			// 	});
			// }

			if (results.length > 0) {
				if (isAutomatic && $(".package-container").attr("unique_identifier") == undefined) {
					getNextBox(results, containers, tempUnpackedItems);
				}
				else {
					let result = results[results.length - 1];
					if (result != null) {
						result = result.AlgorithmPackingResults[result.AlgorithmPackingResults.length - 1];
					}
					let percentage = result.PercentContainerVolumePacked == 0 ? -21 : (result.PercentContainerVolumePacked - 18);
					$cartContainer.find(".background").css("height", percentage + "%");
					$cartContainer.find("svg").css("bottom", percentage + "%");

					let packageItems = [];
					results.forEach(containerPackingResult => {
						let aResults = [];
						containerPackingResult["AlgorithmPackingResults"].forEach(packingResult => {
							aResults.push(ko.mapping.fromJS({
								algorithmName: "Algo Name", PackTimeInMilliseconds: packingResult.PackTimeInMilliseconds, PercentContainerVolumePacked: packingResult.PercentContainerVolumePacked,
								PackedItems: packingResult.PackedItems, UnpackedItems: packingResult.UnpackedItems
							}));
							packageItems.push(packingResult.PackedItems);
						});
						let ctr = 1;
						containers.forEach(container => {

							if (container.ID == containerPackingResult["ContainerID"]) {
								container.Name = "Box " + ctr;
								ctr++;
								container.AlgorithmPackingResults = aResults;
							}
						});
					});

					let containerInd = 1;
					containers.forEach(container => {
						ShowSpecificPackingView1(container, packageItems, containerInd);
						containerInd++;
					});
				}
			}
		}

		let getNextBox = function (results, containers, unpackedItems) {
			let curContainer = containers[containers.length - 1];
			console.log("HEREEEEE containers", containers)
			console.log("HEREEEEE curContainer", curContainer)
			if (curContainer) {
				$.ajax({
					type: "GET",
					url: "/get_next_box?cargo_type=" + curContainer.CargoType + "&box_size=" + curContainer.BoxType,
					success: function (result) {
						if (result.container) {
							let container = {
								ID: result.container.id,
								CargoType: result.container.CargoType,
								BoxType: result.container.BoxType,
								Name: result.container.Name,
								Length: result.container.Length,
								Height: result.container.Height,
								Width: result.container.Width
							};

							var boxTabs = $(".box-tabs");
							let boxInd = $(".box-tabs .box-tab").length + 1;
							let boxClass = boxInd == 1 ? "box-tab active" : "box-tab";
							boxTabs.append("<div class='" + boxClass + "' value='" + boxInd + "'>Box " + boxInd + "</div>");

							containers.push(container);

							let containerPackingResult = new ContainerPackingResult(container.ID);
							let algorithmResult = new AlgorithmPackingResult(new EB_AFIT(container, unpackedItems));

							let containerVolume = container.Length * container.Width * container.Height;
							let itemVolumePacked = sum(algorithmResult.PackedItems, 'Volume');
							let itemVolumeUnpacked = sum(algorithmResult.UnpackedItems, 'Volume');
							let tempUnpackedItems = algorithmResult.UnpackedItems;

							algorithmResult.PercentContainerVolumePacked = itemVolumePacked / containerVolume * 100, 2;
							algorithmResult.PercentItemVolumePacked = itemVolumePacked / (itemVolumePacked + itemVolumeUnpacked) * 100, 2;

							containerPackingResult.AlgorithmPackingResults.push(algorithmResult);
							results.push(containerPackingResult);

							if (tempUnpackedItems.length > 0) {
								getNextBox(results, containers, tempUnpackedItems);
							}
							else {
								let $cartContainer = $(".change-cart-container");
								let result = results[results.length - 1];
								if (result != null) {
									result = result.AlgorithmPackingResults[result.AlgorithmPackingResults.length - 1];
								}
								let percentage = result.PercentContainerVolumePacked == 0 ? -21 : (result.PercentContainerVolumePacked - 18);
								$cartContainer.find(".background").css("height", percentage + "%");
								$cartContainer.find("svg").css("bottom", percentage + "%");

								let packageItems = [];
								results.forEach(containerPackingResult => {
									let aResults = [];
									containerPackingResult["AlgorithmPackingResults"].forEach(packingResult => {
										aResults.push(ko.mapping.fromJS({
											algorithmName: "Algo Name", PackTimeInMilliseconds: packingResult.PackTimeInMilliseconds, PercentContainerVolumePacked: packingResult.PercentContainerVolumePacked,
											PackedItems: packingResult.PackedItems, UnpackedItems: packingResult.UnpackedItems
										}));
										packageItems.push(packingResult.PackedItems);
									});
									let ctr = 1;
									containers.forEach(container => {

										if (container.ID == containerPackingResult["ContainerID"]) {
											container.Name = "Box " + ctr;
											ctr++;
											container.AlgorithmPackingResults = aResults;
										}
									});
								});

								let containerInd = 1;
								containers.forEach(container => {
									ShowSpecificPackingView1(container, packageItems, containerInd);
									containerInd++;
								});
							}
						}
					}
				});
			}
		}

		isAutomatic = $(".package-container").attr("isVessel") == "false";

		if ($(".package-container").hasClass("dashboard")) {
			fetchCurrentOrderPackage();
		}
		else {
			fetchCurrentCartPackage();
		}
	};

	function startTimer() {
		tens++;

		if (tens > 99) {
			seconds++;
			tens = 0;
		}
	}

	$(document).on("click", ".tabs .tab", function () {
		$(this).parents(".tabs").find(".active").removeClass("active");
		$(this).addClass("active");
		$(".items-container").removeClass("active");
		$(".items-container" + $(this).attr("value")).addClass("active");
	});
	$(document).on("click", ".box-tabs .box-tab", function () {
		let activeBox = $(this).parents(".box-tabs").find(".active");
		if (activeBox.length) {
			var selectedContainer = scene.getObjectByName('container' + activeBox.attr("value"));
			if (selectedContainer != undefined)
				selectedContainer.visible = false;
			activeBox.removeClass("active");
		}
		$(this).addClass("active");
		selectedContainer = scene.getObjectByName('container' + $(this).attr("value"));
		if (selectedContainer != undefined)
			selectedContainer.visible = true;

		$(".box-dimensions").removeClass("active");
		$(".box-dimensions.box-" + $(this).attr("value")).addClass("active");
	});
	let isFirst = true;
	$(document).on("click", ".items-container.packed .item", function () {
		if (isFirst) {
			isFirst = false;
			var selectedContainer = scene.getObjectByName('container1');
			if (selectedContainer != undefined) {
				selectedContainer.visible = false;
			}
		}

		if ($(this).hasClass("active")) {
			$(this).removeAttr("style");
			$(this).removeClass("active");
			$(".items-container.packed").find(".item").each(function (i, item) {
				var object = scene.getObjectByName($(item).attr("value"), true);
				object.material.opacity = 0.2;
				object.material.color.setHex(colorObjs[$(item).attr("value")]);
			});
		}
		else {
			var $activeItem = $(".items-container.packed").find(".active");

			if ($activeItem.length) {
				$activeItem.removeAttr("style");
				var activeObject = scene.getObjectByName($activeItem.attr("value"), true);
				activeObject.material.opacity = 0.2;
				activeObject.material.color.setHex(0x808080);
				$activeItem.removeClass("active");

				var selectedContainer = scene.getObjectByName('container' + $activeItem.attr("index"));
				if (selectedContainer != undefined) {
					selectedContainer.visible = false;
				}
			}
			else {
				$(".items-container.packed").find(".item").each(function (i, item) {
					var object = scene.getObjectByName($(item).attr("value"), true);
					object.material.opacity = 0.2;
					object.material.color.setHex(0x808080);
				});
			}

			let $value = $(this).attr("value");
			$(this).addClass("active");
			let stringColor = (parseInt($(this).attr("color")).toString(16)).split('.')[0];
			$(this).attr("style", "background-color: #" + stringColor);

			var activeObject = scene.getObjectByName($value, true);
			activeObject.material.opacity = 1;
			activeObject.material.color.setHex(colorObjs[$value]);

			// var selectedContainer = scene.getObjectByName('container'+$(this).attr("index"));
			// if(selectedContainer != undefined)  {
			// 	selectedContainer.visible=true;
			// }

			$(".box-tabs .box-tab[value='" + $(this).attr("index") + "']").click();
		}
	});


	$(() => {
		$('[data-toggle="tooltip"]').tooltip();
		InitializeDrawing();

		ko.mapping = mapping;
		viewModel = new ViewModel();
		ko.applyBindings(viewModel);

		// init();
		// animate();
		isAutomatic = $(".package-container").attr("isVessel") == "false";
	});
}

var sum = function (items, prop) {
	return items.reduce(function (a, b) {
		return a + b[prop];
	}, 0);
};

var ItemToPack = function () {
	this.ID = '';
	this.Name = '';
	this.Length = '';
	this.Width = '';
	this.Height = '',
		this.Quantity = '';
}

var Container = function () {
	this.ID = '';
	this.Name = '';
	this.Length = '';
	this.Width = '';
	this.Height = '';
	this.AlgorithmPackingResults = [];
}

if ($(".agent-body").length && ($(".change-cart-container").length || $(".agent-cart").length)) {

	let cur_size = "";
	let next_size = "";
	let num_box = "";
	let add_to_cart = true;
	let $url = "";
	let isShoppingCart = false;
	let isAirCargo = false;

	$(document).ready(() => {
		if ($(".change-cart-container").attr("cart_type") == "balikbayan") {
			if ($(".change-cart-container").attr("delivery_type") == "air")
				isAirCargo = true;

			fetchCurrentCart();
		}
		else {
			isShoppingCart = true;
			let percentage = 82;
			$(".change-cart-container").find(".background").css("height", percentage + "%");
			$(".change-cart-container").find("svg").css("bottom", percentage + "%");
		}

		if ($(".homepage-product-detail").length) {
			let $homepage = $(".homepage-product-detail");
			let $details = $(".homepage-details-container");

			$homepage.on("click", ".add-to-cart", function () {
				$homepage.find(".add-to-cart").addClass("hide");
				$homepage.find(".pending-cart").removeClass("hide");
				if (isShoppingCart) {
					addToCart(true);
				}
				else {
					var $form = $homepage.find(".order-form");

					let item = {
						ID: $form.find(".product-id").val(),
						Quantity: parseInt($form.find(".quantity-input").val()),
						Weight: parseFloat($details.find(".shipment-weight").attr("value")),
						WeightType: parseFloat($details.find(".shipment-weight-type").attr("value")),
						Name: $homepage.find(".product-name").attr("value"),
						Dim1: parseFloat($details.find(".shipment-length").attr("value")),
						Dim2: parseFloat($details.find(".shipment-width").attr("value")),
						Dim3: parseFloat($details.find(".shipment-height").attr("value"))
					}

					fetchCurrentCart(item);
				}
			});
			$homepage.on("click", ".remove-cart", function () {
				$(this).addClass("hide");
				$homepage.find(".pending-cart").removeClass("hide");
				var $form = $homepage.find(".order-form");
				$.ajax({
					type: "DELETE",
					url: "/sellers/products/remove_from_cart",
					data: { prod_id: $form.find(".product-id").val() },
					success: function (result) {
						$homepage.find(".pending-cart").addClass("hide");
						if (result.status == 200) {
							$homepage.find(".quantity-text, .plus-minus-container").removeClass("invisible");
							$homepage.find(".add-to-cart").removeClass("hide");

							fetchCurrentCart();
						}
						else {
							console.log(result.error);
						}
					},
					error: function (result) {
					}
				});
			});
		}

		$("#upgradeBoxModal").on("click", ".btn-upgrade", function () {
			let $parentModal = $("#upgradeBoxModal");
			$parentModal.find(".loading-row").removeClass("hide");
			$parentModal.find(".upgrade-message, .num-boxes-message, .button-row").addClass("hide");
			$parentModal.find(".loading-message").removeClass("hide").html("Upgrading current box");
			$.ajax({
				type: "PUT",
				url: "/upgrade_current_cart",
				success: function (result) {
					$parentModal.find(".loading-row").addClass("hide");
					$parentModal.find(".upgrade-message, .num-boxes-message").removeClass("hide");
					$parentModal.find(".loading-message").addClass("hide").html("");
					if (result.status == 200) {
						let $homepage = $(".homepage-product-detail");
						$parentModal.find(".btn-cancel").click();
						$homepage.find(".add-to-cart").click();
						cur_size = result.curr_box_size;
						next_size = result.next_size;
						$parentModal.find(".upgrade-messsage").html("Upgrade to a larger box <span.next-box (" + cur_size + ") or add another box ?");
					}
					else {
						// console.log("error: " + result.error)
						$parentModal.find(".upgrade-message").html(result.error);
						$parentModal.find(".button-row .btn-upgrade").addClass("hide");
						$parentModal.find(".button-row").removeClass("hide");
					}
				}
			});
		});

		$("#upgradeBoxModal").on("click", ".btn-add", function () {
			let $parentModal = $("#upgradeBoxModal");
			$parentModal.find(".loading-row").removeClass("hide");
			$parentModal.find(".upgrade-message, .num-boxes-message, .button-row").addClass("hide");
			$parentModal.find(".loading-message").removeClass("hide").html("Adding box");
			$.ajax({
				type: "PUT",
				url: "/add_current_cart",
				success: function (result) {
					$parentModal.find(".loading-row").addClass("hide");
					$parentModal.find(".upgrade-message, .num-boxes-message").removeClass("hide");
					$parentModal.find(".loading-message").addClass("hide").html("");
					if (result.status == 200) {
						let $homepage = $(".homepage-product-detail");
						$parentModal.find(".btn-cancel").click();
						$homepage.find(".add-to-cart").click();
						num_box = result.num_box;
					}
					else {
						console.log("error: " + result.error)
					}
				}
			});
		});

		$(".agent-cart").on("click", ".summary-container .btn-checkout", function () {
			var $agentCart = $(".agent-cart");
			console.log('.btn-checkout clicked',)
			checkBoxes();
		});
		$("#loadingModal").on("click", ".checkout-redirect", function () {
			window.location.href = $url;
		});
	});

	var checkoutCart = function (isValid, containers = []) {
		var $agentCart = $(".agent-cart");
		if (isValid) {
			$('#loadingModal').find(".loading-row").removeClass("hide");
			$('#loadingModal').find(".message-row, .button-row").addClass("hide");
			$('#loadingModal').find(".message, .error").addClass("hide").html("");
			$("button.loading-btn").click();
			var $ord_obj = [];
			var $cartForm = $agentCart.find(".cart-form");
			$agentCart.find(".stores .store-row").each(function () {
				var $store = $(this);
				var $products = [];
				if ($store.find(".product .input-checkbox:checked").length > 0) {
					$('<input>').attr({
						type: 'hidden',
						name: 'stores[][storeId]',
						value: $store.attr("storeId")
					}).appendTo($cartForm);
				}
				$store.find(".product .input-checkbox:checked").each(function () {
					var $parent = $(this).parents(".product");
					var $storeId = $parent.attr("storeId");
					$('<input>').attr({
						type: 'hidden',
						name: 'stores[][products][][cart_prod_id]',
						value: $parent.attr("cart_prod_id")
					}).appendTo($cartForm);
					$('<input>').attr({
						type: 'hidden',
						name: 'stores[][products][][prod_id]',
						value: $parent.attr("prodId")
					}).appendTo($cartForm);
					$('<input>').attr({
						type: 'hidden',
						name: 'stores[][products][][price]',
						value: parseFloat($parent.find(".information-container .price-value").attr("value"))
					}).appendTo($cartForm);
					$('<input>').attr({
						type: 'hidden',
						name: 'stores[][products][][quantity]',
						value: parseInt($parent.find(".information-container .quantity-input").val())
					}).appendTo($cartForm);
				});
			});
			$.each(containers, function (ind, container_obj) {
				if (container_obj.items.length > 0) {
					let container = container_obj.container;
					let divContainer = $('<div>').attr({
						class: 'container'
					});
					$('<input>').attr({
						type: 'hidden',
						name: 'boxes[][cargo_type]',
						value: container.CargoType
					}).appendTo(divContainer);
					$('<input>').attr({
						type: 'hidden',
						name: 'boxes[][box_type]',
						value: container.BoxType
					}).appendTo(divContainer);
					$.each(container_obj.items, function (indItem, item) {
						$('<input>').attr({
							type: 'hidden',
							name: 'boxes[][items][][id]',
							value: item.ID
						}).appendTo(divContainer);
					});

					divContainer.appendTo($cartForm);
				}
			});
			$.ajax({
				type: "PUT",
				url: "/create_order",
				data: new FormData($cartForm[0]),
				contentType: false,
				cache: false,
				processData: false,
				success: function (result) {
					$('#loadingModal').find(".loading-row, .btn-confirm").addClass("hide");
					$('#loadingModal').find(".message-row, .button-row, .btn-redirect").removeClass("hide");
					$('#loadingModal').find(".btn-redirect").addClass("checkout-redirect");
					$('#loadingModal').find(".btn-redirect").removeClass("btn-redirect");
					if (result.status == 200) {
						$url = "checkout?order_id=" + result.orderId;
						$('#loadingModal').find(".message").removeClass("hide").html(result.message);
						setTimeout(function () {
							$('#loadingModal').find(".checkout-redirect").trigger("click");
						}, 1000);
					}
					else {
						$('#loadingModal').find(".error").removeClass("hide").html(result.error);
					}
				},
				error: function (result) {
				}
			});
		}
		else {
			$(".upgrade-box-btn").click();
			setUpgradeContainer();
		}
	}

	var checkBoxes = function () {
		let items = [];
		let containers = [];
		var $agentCart = $(".agent-cart");
		console.log('here agent cart', $agentCart)
		if ($agentCart.attr("cart_type") == "balikbayan") {
			$agentCart.find(".boxes .box").each(function () {
				var $box = $(this);
				containers.push({
					ID: $box.attr("value"),
					Name: $box.find(".box-name").attr("value"),
					Weight: parseFloat($box.find(".box-weight").attr("value")),
					CargoType: $box.find(".box-cargo-type").attr("value"),
					BoxType: $box.find(".box-box-type").attr("value"),
					Length: parseFloat($box.find(".box-length").attr("value")),
					Height: parseFloat($box.find(".box-height").attr("value")),
					Width: parseFloat($box.find(".box-width").attr("value")),
					WeightType: $box.find(".box-weight-type").attr("value")
				});
			});
			$agentCart.find(".store-row .product .input-checkbox:checked").each(function () {
				var $this = $(this).parents(".product");
				let i = 1;
				let max = parseInt($this.find(".quantity-input").val());
				let weightValue = weight_conversion($this.find(".weight").attr("value"), $this.find(".weight-type").attr("value"), containers[0].WeightType);

				do {
					i = i + 1;
					items.push({
						ID: $this.attr("prodId") + " " + i,
						Quantity: 1,
						Name: $this.find(".information-container .title").html(),
						Dim1: parseFloat($this.find(".shipment-length").attr("value")),
						Dim2: parseFloat($this.find(".shipment-width").attr("value")),
						Dim3: parseFloat($this.find(".shipment-height").attr("value")),
						Weight: weightValue,
						WeightType: containers[0].WeightType
					});
				} while (i <= max);
			});
			add_to_cart = false;
			if ($agentCart.attr("cargo_type") == "vessel")
				setPacking(containers, items, {});
			else
				checkWeight(containers, items, {});
		}
		else {
			$agentCart.find(".store-row .product .input-checkbox:checked").each(function () {
				var $this = $(this).parents(".product");
				items.push({
					ID: $this.attr("prodId"),
					Quantity: parseInt($this.find(".quantity-input").val()),
					Name: $this.find(".information-container .title").html(),
					Dim1: parseFloat($this.find(".shipment-length").attr("value")),
					Dim2: parseFloat($this.find(".shipment-width").attr("value")),
					Dim3: parseFloat($this.find(".shipment-height").attr("value")),
					Weight: parseFloat($this.find(".weight").attr("value")),
					WeightType: $this.find(".weight-type").attr("value")
				});
			});
			console.log("HERE ITEMS IN CARTsss", items)
			smartPacking(items, [], [], "volume", true);
			// checkoutCart(true);
		}
	}

	var addToCart = function (isValid, isDecrased = true) {
		let $homepage = $(".homepage-product-detail");
		if (isValid) {
			var $form = $homepage.find(".order-form");

			$.ajax({
				type: "PUT",
				url: "/sellers/products/" + $form.find(".product-id").val() + "/add_to_cart",
				data: new FormData($form[0]),
				contentType: false,
				cache: false,
				processData: false,
				success: function (result) {
					$homepage.find(".pending-cart").addClass("hide");
					if (result.status == 200) {
						$homepage.find(".quantity-text, .plus-minus-container").addClass("invisible");
						$homepage.find(".success-cart").removeClass("hide");
						setTimeout(function () {
							$homepage.find(".success-cart").addClass("hide");
							$homepage.find(".remove-cart").removeClass("hide");
						}, 2000);

						if (!isShoppingCart)
							fetchCurrentCart();
					}
				}
			});
		}
		else {
			$homepage.find(".add-to-cart").removeClass("hide");
			$homepage.find(".pending-cart").addClass("hide");
			$(".upgrade-box-btn").click();
			setUpgradeContainer(isDecrased);
		}
	}

	var fetchCurrentCart = function (item = null) {
		$.ajax({
			type: "GET",
			url: "/get_current_cart",
			success: function (result) {
				if (item) {
					if (result.status == 200) {
						// if(isAirCargo){
						// 	checkWeight(result.containers, result.items, item);
						// }
						// else {
						setPacking(result.containers, result.items, item);
						// }
					}
					else {
						console.log("error: " + result.error)
					}
				}
				else {
					if (result.status == 200) {
						if (isAirCargo) {
							checkWeight(result.containers, result.items);
						}
						else {
							setPacking(result.containers, result.items);
						}
					}
					else {
						console.log("error: " + result.error)
					}
				}
			}
		});
	}

	var setPacking = function (containersObj, items, item = null) {
		let containers = [];
		let packageItems = [];

		var boxTabs = $(".box-tabs");
		boxTabs.html("");
		let boxInd = 1;
		let primeContainer = containersObj[0];
		$.each(containersObj, function (ind, container) {
			let boxClass = boxInd == 1 ? "box-tab active" : "box-tab";
			boxTabs.append("<div class='" + boxClass + "' value='" + boxInd + "'>Box " + boxInd + "</div>");
			containers.push({
				ID: container.id,
				CargoType: container.CargoType,
				BoxType: container.BoxType,
				Name: container.Name,
				Length: container.Length,
				Height: container.Height,
				Width: container.Width,
				Weight: weight_conversion(container.Weight, container.WeightType, primeContainer.WeightType),
				WeightType: primeContainer.WeightType
			});
			boxInd++;
		});
		// for(let x = 1; x <= 1; x++) {
		// 	containers.push({ ID: x, Name: "Container " + x,  Length: 10, Width: 10, Height: 10 });
		// }

		// Dim1 = Length
		// Dim2 = Width
		// Dim3 = Height

		items.forEach(item => {
			packageItems.push({
				ID: item.ID,
				Name: item.Name,
				Dim1: item.Dim1,
				Dim2: item.Dim2,
				Dim3: item.Dim3,
				Quantity: item.Quantity,
				Weight: weight_conversion(item.Weight, item.WeightType, containers[0].WeightType),
				WeightType: containers[0].WeightType
			});
		});

		if (item && item.ID != undefined) {
			packageItems.push(item);
		}

		let results = [];

		let tempUnpackedItems = packageItems;
		let hasChanged = true;
		let usedContainers = [];
		let numItems = 0;
		// do {
		containers.forEach(container => {
			let containerPackingResult = new ContainerPackingResult(container.ID);
			let algorithmResult = new AlgorithmPackingResult(new EB_AFIT(container, tempUnpackedItems));

			let containerVolume = container.Length * container.Width * container.Height;
			let itemVolumePacked = sum(algorithmResult.PackedItems, 'Volume');
			let itemVolumeUnpacked = sum(algorithmResult.UnpackedItems, 'Volume');
			tempUnpackedItems = algorithmResult.UnpackedItems;

			algorithmResult.PercentContainerVolumePacked = itemVolumePacked / containerVolume * 100, 2;
			algorithmResult.PercentItemVolumePacked = itemVolumePacked / (itemVolumePacked + itemVolumeUnpacked) * 100, 2;

			containerPackingResult.AlgorithmPackingResults.push(algorithmResult);
			results.push(containerPackingResult);

			numItems += algorithmResult.PackedItems.length;
			if (algorithmResult.PackedItems.length > 0) {
				usedContainers.push({ container: container, items: algorithmResult.PackedItems });
			}
		});
		// }while(hasChanged && tempUnpackedItems.length > 0);

		let $cartContainer = $(".change-cart-container");

		if (item) {
			if (add_to_cart) {
				addToCart(tempUnpackedItems.length == 0, numItems > 0);
			}
			else {
				checkoutCart(tempUnpackedItems.length == 0, usedContainers);
				add_to_cart = true;
			}
		}
		else if (results.length > 0) {
			let result = results[results.length - 1];
			if (result != null) {
				result = result.AlgorithmPackingResults[result.AlgorithmPackingResults.length - 1];
			}
			let percentage = result.PercentContainerVolumePacked == 0 ? -21 : (result.PercentContainerVolumePacked - 18);
			$cartContainer.find(".background").css("height", percentage + "%");
			$cartContainer.find("svg").css("bottom", percentage + "%");
			$cartContainer.find(".image-container .badge-danger").html(numItems);

			$(".cart-container .badge-danger").html(numItems);
		}
	}

	var checkWeight = function (containersObj, items, item = null) {
		let containers = [];
		let packageItems = [];

		let maxWeight = 0;
		let totalWeight = 0;
		let totalQuantity = 0;
		let containerArray = [];

		let primeContainer = containersObj[0];
		$.each(containersObj, function (ind, container) {
			containers.push({
				container: {
					ID: container.id,
					Length: container.Length,
					BoxType: container.BoxType,
					Height: container.Height,
					Width: container.Width,
					UnitMeasure: container.UnitMeasure,
					CargoType: container.CargoType,
					Weight: weight_conversion(container.Weight, container.WeightType, primeContainer.WeightType),
					WeightType: primeContainer.WeightType
				}, items: []
			});
			maxWeight += container.Weight;
			containerArray.push({ container: container, items: [] });
		});
		items.forEach(item => {
			packageItems.push({
				ID: item.ID,
				Name: item.Name,
				Dim1: item.Dim1,
				Dim2: item.Dim2,
				Dim3: item.Dim3,
				Quantity: item.Quantity,
				Weight: weight_conversion(item.Weight, item.WeightType, containers[0].WeightType),
				WeightType: containers[0].WeightType
			});
			totalWeight += item.Weight * item.Quantity;
			totalQuantity += item.Quantity;
		});

		containerArray[0].items = items;

		if (item) {
			if (item.Weight != undefined) {
				totalWeight += item.Weight * item.Quantity;
				totalQuantity += item.Quantity;
			}

			if (totalWeight <= maxWeight) {
				if (add_to_cart) {

					addToCart(true);
				}
				else {
					// setPacking(containers, packageItems, {});

					// checkoutCart(true, containerArray);
					let tempUnpackedItems = packageItems;
					// do {
					let containerInd = 1;
					let containersObj = [];
					containers.forEach(containerObject => {
						let container = containerObject.container;
						let containerPackingResult = new ContainerPackingResult(container.ID);
						let algorithmResult = new AlgorithmPackingResult(new EB_AFIT(container, tempUnpackedItems));

						let containerVolume = container.Length * container.Width * container.Height;
						let itemVolumePacked = sum(algorithmResult.PackedItems, 'Volume');
						let itemVolumeUnpacked = sum(algorithmResult.UnpackedItems, 'Volume');
						tempUnpackedItems = algorithmResult.UnpackedItems;

						algorithmResult.PercentContainerVolumePacked = itemVolumePacked / containerVolume * 100, 2;
						algorithmResult.PercentItemVolumePacked = itemVolumePacked / (itemVolumePacked + itemVolumeUnpacked) * 100, 2;
						containersObj.push({ container: container, items: algorithmResult.PackedItems });
					});

					if (tempUnpackedItems.length > 0) {
						smartPacking(tempUnpackedItems, [], containersObj, "volume", true);
					}
					else {
						checkoutCart(true, containersObj);
					}
					add_to_cart = true;
				}
			}
			else {
				let $homepage = $(".homepage-product-detail");
				$homepage.find(".add-to-cart").removeClass("hide");
				$homepage.find(".pending-cart").addClass("hide");
				$(".upgrade-box-btn").click();
				setUpgradeContainer();
			}
		} else {
			let $cartContainer = $(".change-cart-container");
			let percentage = totalWeight == 0 ? -21 : (((totalWeight / maxWeight) * 100) - 18);
			$cartContainer.find(".background").css("height", percentage + "%");
			$cartContainer.find("svg").css("bottom", percentage + "%");
			$cartContainer.find(".image-container .badge-danger").html(totalQuantity);

			$(".cart-container .badge-danger").html(totalQuantity);
		}
	}

	var smartPacking = function (items, results = [], containers = [], addType = "volume", isFirst = false) {
		let curContainer = containers[containers.length - 1];
		let url = !isFirst && curContainer ? "/get_next_box?cargo_type=" + curContainer.container.CargoType + "&box_size=" + curContainer.container.BoxType : "/get_next_box?is_first=true";
		url += "&add_type=" + addType;
		$.ajax({
			type: "GET",
			url: url,
			success: function (result) {
				console.log("here success result", result)
				if (result.container) {
					let primeContainer = containers[0];
					let container = {
						ID: result.container.id,
						CargoType: result.container.CargoType,
						BoxType: result.container.BoxType,
						Name: result.container.Name,
						Length: result.container.Length,
						Height: result.container.Height,
						Width: result.container.Width,
						Weight: primeContainer ? weight_conversion(result.container.Weight, result.container.WeightType, primeContainer.WeightType) : result.container.Width,
						WeightType: primeContainer ? primeContainer.WeightType : result.container.WeightType
					};

					let containerPackingResult = new ContainerPackingResult(container.ID);
					let algorithmResult = new AlgorithmPackingResult(new EB_AFIT(container, items));

					let containerVolume = container.Length * container.Width * container.Height;
					let itemVolumePacked = sum(algorithmResult.PackedItems, 'Volume');
					let itemVolumeUnpacked = sum(algorithmResult.UnpackedItems, 'Volume');
					let tempUnpackedItems = algorithmResult.UnpackedItems;

					algorithmResult.PercentContainerVolumePacked = itemVolumePacked / containerVolume * 100, 2;
					algorithmResult.PercentItemVolumePacked = itemVolumePacked / (itemVolumePacked + itemVolumeUnpacked) * 100, 2;

					if (algorithmResult.PackedItems.length > 0) {
						console.log("here algorithmResult", algorithmResult)
						console.log("here addType", addType)
						if (addType == "volume" && containers.length > 0) {
							containers[containers.length - 1] = { container: container, items: algorithmResult.PackedItems };
						}
						else if (containers[containers.length - 1]) {
							if (containers[containers.length - 1].items.length > 0) {
								containers.push({ container: container, items: algorithmResult.PackedItems });
							}
						}
						else {
							containers.push({ container: container, items: algorithmResult.PackedItems });
						}
						console.log("here containers", containers)
					}
					if (tempUnpackedItems.length > 0) {
						console.log("here tempUnPackedItems", tempUnpackedItems)
						if (addType == "box") {
							console.log('add type', tempUnpackedItems.length)
							smartPacking(tempUnpackedItems, results, containers, "volume", true);
						}
						else if (addType == "volume") {
							if (result.next_box) {
								console.log('add type else if volume', tempUnpackedItems.length)
								smartPacking(items, results, containers, addType, false);
							}
							else {
								console.log('add type else', tempUnpackedItems.length)
								smartPacking(tempUnpackedItems, results, containers, "box", false);
							}
						}
					}
					else {
						// $.each(containers, function(ind, container){
						// 	// console.log("container " + JSON.stringify(container))
						// 	if(container.items)
						// 		console.log("container " + JSON.stringify(container.items.length))
						// });
						console.log("here checkoutCart would have been called here")
						console.log("heres the containers that was going to be passed to checkout", containers)
						checkoutCart(true, containers);
					}
				}
				else if (result.message && result.message == "Largest box") {
					console.log('on the last box', result)
					smartPacking(items, results, containers, "box", true);
				}
			},
			error: function (result) {
				console.log("here result ERROR:", result)
			}
		});
	}

	var setUpgradeContainer = function (isDecreased = true) {
		var $parentModal = $("#upgradeBoxModal");
		$parentModal.find(".loading-row").addClass("hide");
		$parentModal.find(".upgrade-message, .upgrade-message-1, .num-boxes-message, .button-row, .button-row .btn-upgade").removeClass("hide");
		$parentModal.find(".loading-message").addClass("hide").html("");
		if (cur_size != "") {
			$parentModal.find(".current-box").html("(" + cur_size + ")");
			$parentModal.find(".next-box").html("(" + next_size + ")");
		}
		if (num_box != "") {
			$parentModal.find(".num-boxes").html(num_box);
		}
		if (next_size == "") {
			$parentModal.find(".button-row .btn-upgade").addClass("hide");
		}
		if (cur_size == "" && next_size == "" && !isDecreased) {
			$parentModal.find(".button-row .btn-upgrade, .button-row .btn-add").addClass("hide");
			$parentModal.find(".upgrade-message, .upgrade-message-1").html("");
			$parentModal.find(".upgrade-message").html("Product cannot be added to cart since none of the boxes can fit the product");
		}
	}
}

function weight_conversion(value, type, preferred_type) {
	let new_value = 0;
	let kg_to_lb = 2.2;
	let lb_to_kg = 0.45;

	if (type == "kgs" && preferred_type == "lbs")
		new_value = value * kg_to_lb;
	else if (type == "lbs" && preferred_type == "kgs")
		new_value = value * lb_to_kg;
	else
		new_value = value;

	return parseFloat(new_value);
}

function measurement_conversion(value, from, to) {
	let new_value = 0;
	let in_to_cm = 2.54;
	let cm_to_in = 0.393701;

	if (from == "in" && to == "cm")
		new_value = value * in_to_cm;
	else if (from == "cm" && to == "in")
		new_value = value * cm_to_in;
	else
		new_value = value;

	return new_value;
}